import React, {useCallback, useEffect, useState} from 'react';
import {GlobalButtonFinal, ModalsWrapper, ModalWindow} from "../GlobalStyles";
import { deleteCookie, getCookie } from 'cookies-next';
import * as Sentry from "@sentry/nextjs";
import { signOut } from 'next-auth/client';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../src/messages';

export default function DialogDelete ({productDeleteUUID,toggleDeleteMultiple,teamDeleteUUID,deleteTeam,isMe,
                                          deleteProductIDMultiple,productVersionDeleteUUID,
                                          onDeleteReloadMembers,
                                      toggleDelete,reloadProducts,userDeleteUUID,type,triggerWarning,
                                          changeLoadingShow}) {

    const [loadingDelete, setLoadingDelete] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false);
    const [deleteTransitionShow, setDeleteTransitionShow] = useState(false);
    const router = useRouter();

    const changeDeleteShow = (variable) => {

        if(variable === true){

            setDeleteShow(variable);
            setTimeout(function() {
                setDeleteTransitionShow(true);
            },1);




        } else{


            setDeleteTransitionShow(false);
            setTimeout(function() {
                setDeleteShow(variable);
            },500);



        }

    };
    const deleteProductCheck = (productID) => {

        changeDeleteShow(true);
    };


    const [deleteState, setDeleteState] = useState(1);

    const [deleteParent, setDeleteParent] = useState("");

    const deleteProduct = (check) => {
        changeLoadingShow(true);
        setLoadingDelete(true);
        closeDeleteShow();
var check2 = "true";

if(check === false){
    check2 = "false";
}

        var newURI = process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"products";
var arrayDel = [productDeleteUUID];

console.log("productDeleteUUID: "+productDeleteUUID);
        if(productVersionDeleteUUID){
            newURI = process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"products/"+productDeleteUUID + "/versions";
            arrayDel = [productVersionDeleteUUID];
        }


        console.log("newURI: "+newURI);

        fetch(newURI+"?check="+check2, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
            },
            body: JSON.stringify(arrayDel),
        })
            .then(response => response.json())
            .then(function(data){
                //  clientLogo
                if(data.message !== undefined){
                    Sentry.captureException("Dialogdelete deleteProduct products/"+productDeleteUUID+": "+ data.message + " : " + getCookie("userMail"));
                }
                closeDeleteShow();
                if(data.message === undefined){

                    reloadProducts();


                } else {

                    changeLoadingShow(false);
                    if(data.status !== 422){
                        setDeleteParent(data.data["0"]["relations"]["0"]["affected"]["0"]["parent"]["name"]);

                        setDeleteState(2);
                    } else {
                        triggerWarning(data.error_code);
                    }

                }

            });

    };

    const deleteProductMultiple = () => {
        changeLoadingShow(true);
        console.log("DELETE MULTIPLE",deleteProductIDMultiple);
        setLoadingDelete(true);
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"products", {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
            },
            body: JSON.stringify(    [deleteProductIDMultiple]),
        })
            .then(response => response.json())
            .then(function(data){

                if(data.message !== undefined){
                    Sentry.captureException("Dialogdelete deleteProductMultiple products/"+productDeleteUUID+": "+ data.message + " : " + getCookie("userMail"));
                }
                closeDeleteShow();
                if(data.message === undefined){
                    // changeDeleteShow(false);
                    //   handleClick();
                    reloadProducts();

                    var countReady = deleteProductIDMultiple.length-1;
                    deleteProductIDMultiple.map((value, index) => {
                        closeDeleteShow();
                        changeLoadingShow(false);

                    })



                } else {
                    closeDeleteShow();
                    if(data.error_code){
                        triggerWarning(data.error_code);
                    }else{
                        triggerWarning(data.message);
                    }
                }

            });

    };

    const deleteUser = (type) => {

        if(type === "team"){
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"teams/current/members/"+userDeleteUUID+"?force=true", {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
                },
            })
                .then(response => response.json())
                .then(function(data){
                    if(data.message !== undefined){
                        Sentry.captureException("deleteUserTeam teams/current/members/"+userDeleteUUID+": "+ data.message + " : " + getCookie("userMail"));
                    }
                    if(data.message === undefined){
                     //   location.reload();
                        if(isMe){
                            deleteCookie('userTeam');
                            deleteCookie('userTeamUUID');

                            router.push('/team-select');
                        } else {
                            closeDeleteShow();
                            onDeleteReloadMembers();
                        }

                       // closeDeleteShow();
                        //router.push('/team-select');
                        //onDeleteReloadMembers();
                    } else {
                        closeDeleteShow();
                        if(data.error_code){
                            triggerWarning(data.error_code);
                        }else{
                            triggerWarning(data.message);
                        }
                    }

                });
        } else {
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"user/current", {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
                },
            })
                .then(response => response.json())
                .then(function(data){
                    if(data.message !== undefined){
                        Sentry.captureException("deleteUser current" + getCookie("userMail"));
                    }
                    if(data.message === undefined){
                       // location.reload();

                        if(isMe){
                            deleteCookie('userTeam');
                            deleteCookie('userTeamUUID');
                            router.push('/team-select');
                            signOutFunction();
                        } else {
                            closeDeleteShow();
                            onDeleteReloadMembers();
                        }
                       // closeDeleteShow();
                       // signOutFunction();

                    } else {
                        closeDeleteShow();
                        if(data.error_code){
                            triggerWarning(data.error_code);
                        }else{
                            triggerWarning(data.message);
                        }
                    }

                });
        }
    };

    let signOutFunction = () => {
        deleteCookie('userTeam');
        deleteCookie('userTeamUUID');
        deleteCookie('usrA');

        signOut({ redirect: false }).then(() => {
            router.push('/'); // Redirect to the dashboard page after signing out
        });

        // signOut();
    };


    let closeDeleteShow = () => {
        changeDeleteShow(false);

        setTimeout(function() {
            setLoadingDelete(false);
        },300);
    };
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const toggleDeleteInside = useCallback((): void => {
        if(showDeleteDialog){
            changeDeleteShow(false);
        }else{
            setDeleteMultiple(false);
            changeDeleteShow(true);
        }


    }, []);
    useEffect(() => {

        toggleDelete && toggleDelete(toggleDeleteInside);
    }, [toggleDelete, toggleDeleteInside]);

    const [deleteMultiple, setDeleteMultiple] = useState(false);

    const toggleDeleteInsideMultiple = useCallback((): void => {
        if(showDeleteDialog){
            changeDeleteShow(false);
        }else{
            setDeleteMultiple(true);
            changeDeleteShow(true);
        }


    }, []);
    useEffect(() => {

        toggleDeleteMultiple && toggleDeleteMultiple(toggleDeleteInsideMultiple);
    }, [toggleDeleteMultiple, toggleDeleteInsideMultiple]);

    const closeModalOutsideClick = (e) => {
        if( e.target.getAttribute("data-closemodal") === "true"){
            changeDeleteShow(false);
        }
    }


    return (
        <>

            <ModalsWrapper className={`deleteCheck  ${deleteTransitionShow ? 'showTransition' : 'hideTransition'} ${!deleteShow ? 'hiddeComplete' : ''}`}
                           data-closemodal={true}
                           onClick={(e)=> closeModalOutsideClick(e)}
            >


                <ModalWindow
                    className={"center modal_dialog_small"}
                    id={'deleteProductDialog'} style={{ minHeight: 'unset',     height: "190px" }}>
                    <div className={'modal-header'}>
                        <h4> <FormattedMessage {...messages.products_modal_delete_title}></FormattedMessage></h4>

                    </div>

                    {deleteState === 1 ?
                        <div className={'modal-content shareProduct'} style={{ display: "block" }}>
                            <span> <FormattedMessage {...messages.products_modal_delete_description}></FormattedMessage> </span>

                            <div className={"modal-buttons"}>

                                <GlobalButtonFinal
                                                   className={"borderBlue btn-circle"}
                                                   onClick={() => changeDeleteShow(false)}>
                                    <FormattedMessage {...messages.products_modal_delete_button_cancel}></FormattedMessage>
                                </GlobalButtonFinal>

                                <GlobalButtonFinal
                                                   className={"backgroundRed btn-circle"} data-uuid={productDeleteUUID}
                                                   onClick={type ? (type === "team_full"? () => deleteTeam() : () => deleteUser(type)) : (deleteMultiple ? () => deleteProductMultiple() : () => deleteProduct(false))}>
                                    <FormattedMessage {...messages.products_modal_delete_button_delete}></FormattedMessage>
                                </GlobalButtonFinal>

                            </div>


                        </div> : null
                    }


                    {deleteState === 2 ?
                        <div className={'modal-content shareProduct'} style={{ display: "block" }}>
                            <span> <FormattedMessage {...messages.products_modal_delete_confirm}></FormattedMessage></span>

                            <div className={"modal-buttons"}>

                                <GlobalButtonFinal
                                                   className={"borderBlue btn-circle"}
                                                   onClick={() => changeDeleteShow(false)}>
                                    <FormattedMessage {...messages.products_modal_delete_button_cancel}></FormattedMessage>
                                </GlobalButtonFinal>

                                <GlobalButtonFinal
                                                   className={"backgroundRed btn-circle"} data-uuid={productDeleteUUID}
                                                   onClick={type ? () => deleteUser(type) : (deleteMultiple ? () => deleteProductMultiple() : () => deleteProduct(false))}>
                                    <FormattedMessage {...messages.products_modal_delete_button_confirm}></FormattedMessage>
                                </GlobalButtonFinal>

                            </div>


                        </div> : null
                    }


                </ModalWindow>


            </ModalsWrapper>


        </>
    )
}